//防抖函数
export let deBounce = (fn, delay) => {
    let timer = null;
    return function (...args) {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(()=> {
            fn(...args);
        }, delay);
    }
}

//节流函数
export let throttle = (fn, delay) => {
    let flag = true;
    return function (...args) {
        if (!flag) return;
        flag = false;
        setTimeout(() => {
            fn(...args);
            flag = true;
        }, delay);
    }
}

//计算年龄
export function ages(str) {
    var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
    if (r == null) return false;
    var d = new Date(r[1], r[3] - 1, r[4]);
    if (
      d.getFullYear() == r[1] &&
      d.getMonth() + 1 == r[3] &&
      d.getDate() == r[4]
    ) {
      var Y = new Date().getFullYear();
      return Y - r[1];
    }
}

//随机数
export function RandomString(length) {
    var str = '';
    for ( ; str.length < length; str += Math.random().toString(36).substr(2) );
    return str.substr(0, length);
}

// 判断是否是小程序的 webview 环境
export function isInWechatMPO() {
    if (
        navigator.userAgent.match(/miniprogram/i) ||
        window.wxjs_environment == "miniprogram"
    ) {
        return true;
    } else {
        return false;
    }
}

//生成二维码
import QRCode from 'qrcode';
export function makecode(text){
    var imgurl = '';
    QRCode.toDataURL(text,
        {
            scale:7,
            margin:2,
            errorCorrectionLevel: 'H',
        },
        function (err, url) {
            imgurl = url;
        }
    );

    return imgurl;
}

//播放音频
class Paly{
    constructor() {
        this.audio = document.createElement("audio");
    }
    play(url){
        let device = sessionStorage.device;
        if( device && (device=='android'||device=='windows')){
            this.audio.src = url;
            this.audio.load();
            this.audio.play();
        }
    }
}

export let audioPaly = new Paly();

//获取cookie
export function getCookie(name){
    var prefix = name + "="
    var start = document.cookie.indexOf(prefix)
    if (start == -1) {
        return null;
    }
    var end = document.cookie.indexOf(";", start + prefix.length)
    if (end == -1) {
        end = document.cookie.length;
    }
    var value = document.cookie.substring(start + prefix.length, end)
    return decodeURI(value);
}

//获取二级域名前缀
export function getDomain(){
   let href =  window.location.href;
   if(href.indexOf("localhost")!=-1){
        return 'localhost';
   }else{
        return href.split(".")[0].split("//")[1];
   }
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function getBinary(file) {
    return new Promise((resolve, reject) => {
        getBase64(file)
          .then((res) => {
              const arr = res.split(",");
              const mime = arr[0].match(/:(.*?);/)[1];
              const bstr = atob(arr[1]);
              let n = bstr.length;
              const u8arr = new Uint8Array(n);
              while (n--) {
                  u8arr[n] = bstr.charCodeAt(n);
              }
              return resolve(new Blob([u8arr], {type: mime}));
          })
          .catch(reject);
    });
}
//计算上上上...周的开始时间和结束时间,week向前推导第几周,time:时间基数
export function getWeek(date,day){
    let time = new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime();
    if(date){   //在时间基数上加减
        time = new Date(new Date(date).toLocaleDateString()).getTime();
    }
    let end;
    if(date){
        if(day>0){
            end = new Date(time-1000*3600*24);
        }else if(day<0){
            end = new Date(time+1000*3600*24);
        }else{
            end = new Date(time);
        }
    }else{
        end = new Date(time);
    }
    let start = new Date(end.getTime()-day*1000*3600*24);
    if(day>0){
        return {
            start:`${start.getFullYear()}-${dateZero(start.getMonth()+1)}-${dateZero(start.getDate())}`,
            end:`${end.getFullYear()}-${dateZero(end.getMonth()+1)}-${dateZero(end.getDate())}`
        }
    }else{
        return {
            start:`${end.getFullYear()}-${dateZero(end.getMonth()+1)}-${dateZero(end.getDate())}`,
            end:`${start.getFullYear()}-${dateZero(start.getMonth()+1)}-${dateZero(start.getDate())}`
        }
    }
    
}

export function dateZero(data){
    return data>9?data:`0${data}`
}