import axios from "@/utils/axios";
import { user_add } from "@/api";
import { Toast } from 'vant';
let addUser = {
    namespaced: true,
    state: {
        iphone:'',
        name:'',
        id_card:'',
    },
    mutations: {            //同步方法
        //提交手动添加信息
        Submit(state,_this){
            let bool = true;
            if(!state.iphone){
                Toast.fail('请输入手机号！');
                bool = false;
            }
            if(!state.id_card && bool){
                Toast.fail('请输入身份证号！');
                bool = false;
            }
            if(!state.name && bool){
                Toast.fail('请输入姓名!');
                bool = false;
            }
            if(bool){
                axios(`/userapi${user_add}`,{
                    phone:state.iphone,
                    id_entity_card:state.id_card,
                    name:state.name,
                    event:2,
                    third_parties:[{
                        source:3
                    }]
                },'POST').then((data)=>{
                    if(data.code == 1){
                        if(_this.$route.params.id){
                            this.dispatch('device/axiosBind',{
                                data:{
                                    token: _this.$route.params.id,   //这里报告的token
                                    user_id:data.data.id
                                },
                                callback:(params)=>params._this.$router.push({name:'prescribeQuestion',params: {id:_this.$route.params.id}}),
                                _this: _this
                            });
                        }else{
                            Toast.fail('绑定失败！');
                        }
                    }else{
                        Toast.fail(data.msg);
                    }
                });
            }
        },

        //改变数值
        change(state,data){
            state[data.key] = data.value;
        },
    },
    actions: {              //异步方法
        addData({commit},_this){
            commit('Submit',_this)
        },
        bindData({commit},_this){
            commit('bind',_this)
        }
    },
    getters: {        //数据过滤
        
    },
}

export default addUser;