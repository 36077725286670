import axios from "@/utils/axios";
import { getMacInfo , userDevice , bindUserid , popupDevice , account_tel } from "@/api";
import { Notify } from 'vant';

let device = {
    namespaced: true,
    state: {
        code:'',
        msg:'',
        qrcode:''
    },
    mutations: {
        //发送请求
        mainAxios(state,params){
            axios(`/api${getMacInfo}`,params.data)
			.then((data)=>{
                if(data.code==1){
                    state.code = data.data;
                    let equipment = {
                        equipment_code:data.data.info.equipment_code,
                        sys_company_id:data.data.info.sys_company_id,
                        code:data.data.info.qrcode,
                        logo:data.data.info.logo,
                        mac:params.data.mac,
                        appid:data.data.app_id?data.data.app_id:'',
                        send_template_select:data.data.info.send_template_select
                    }
                    sessionStorage.setItem("equipment",JSON.stringify(equipment));
                    sessionStorage.setItem("flow", JSON.stringify({
                        ...data.data.flow,
                        testing_type:data.data.info.testing_type,
                        device:true
                    }));
                }else{
                    Notify(data.msg);
                    state.msg = data.msg;
                    params._this.$router.push({
                        name:'code',query: {
                            mac:params.data.mac,
                            ban:data.code==402?false:true
                        }
                    });
                }
            });
        },
        
        //手机号或姓名登录
        telAxios(state,params){
            let _this = this;
            axios(`/userapi${account_tel}`,params.data,'POST').then((data)=>{
                if(data.code==1){
                    sessionStorage.setItem("token",data.data.token);
                    if(params.token){
                        _this.dispatch('device/axiosBind',{
                            data:{
                                token: params.token    //这里报告的token
                            },
                            url: params.url,
                            query:params.query,
                            _this: params._this
                        });
                    }else{
                        if(params.func){
                            params.func(data.data);
                        }else{
                            params._this.$router.push({
                                name:params.url,
                                query:params.query
                            })
                        }
                    }
                }
            })
        },

        //轮询用户扫码获取token
        lunAxios(state,params){
            let _this = this;
            axios(`/userapi${userDevice}`,params.data)
            .then((data)=>{
                if(data.code == 1 && data.data.token ){
                    sessionStorage.setItem("token",data.data.token);
                    clearInterval(window.Lun);
                    if(params.token){
                        _this.dispatch('device/axiosBind',{
                            data:{
                                token: params.token    //这里报告的token
                            },
                            url: params.url,
                            callback:params.callback,
                            _this: params._this
                        });
                    }else{
                        params._this.$router.push({
                            name:params.url,
                            query:params.query
                        })
                        
                    }
                }
            });
        },

        //添加设备登录的uuid
        popupAxios(state,params){
            axios(`/userapi${popupDevice}`,params.data,'POST')
            .then((data)=>{
                if(data.code == 1){
                    params.openFun(params.data)
                }
            });
        },

        //用将用户和设备报告绑定
        bindAxios(state,params){
            axios(`/api${bindUserid}`,params.data,'POST')
            .then((data)=>{
                if(data.code == 1){
                    if(params.callback){
                        params.callback(params);
                    }else if(params.url=='report'){
                        Notify({ type: 'success', message: '登录成功！' });
                        setTimeout(function(){
                            window.location.reload();
                        },1000)
                    } else if(params.url){
                        params._this.$router.push({
                            name:params.url,
                            query:params.query
                        })
                    }
                }else{
                    Notify(data.msg);
                }
            });
        }
    },

    actions: {              //异步方法
        axiosData({commit},params){
            commit('mainAxios',params)
        },
        axiosLun({commit},params){
            commit('lunAxios',params)
        },
        popupMain({commit},params){
            commit('popupAxios',params)
        },
        axiosBind({commit},params){
            commit('bindAxios',params)
        },
        axiosTel({commit},params){
            commit('telAxios',params)
        }
    },
    getters: {},
}

export default device;