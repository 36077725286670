import axios from "@/utils/axios";
import {  add_zhengshu , osspolicy } from "@/api";
import { Toast } from 'vant';
let upload = {
    namespaced: true,
    state: {
        upload1:'',
        upload2:''
    },
    mutations: {
        uploadAxios(state,parem){
            axios(`/api${osspolicy}`).then((data)=>{
                if(data.code == 1){
                    let res = data.data
                    const formData = new FormData();
                    let dir = res.Key + new Date().getTime();
                    formData.append("OSSAccessKeyId", res.OSSAccessKeyId);
                    formData.append("Policy", res.Policy);
                    formData.append("Signature", res.Signature);
                    formData.append("Key", dir);
                    formData.append("Callback", res.Callback);
                    formData.append("File", parem.file);
                    axios(`https://${res.Host}`,formData,'POST',{},true).then(ress => {
                        if(ress.Code==0){
                            if(parem.type==1){
                                state.upload1 = `https://${res.Host}/${ress.Data.FileName}`
                            }else{
                                state.upload2 = `https://${res.Host}/${ress.Data.FileName}`
                            }
                        }
                    })
                }
            })
        },
        uploadSubmit(state,_this){
            axios(`/userapi${add_zhengshu}`,{
                doctor_qualification_certificate:state.upload1,
                doctor_practice_certificate:state.upload2,
                status:3
            },'PUT').then((data)=>{
                if(data.code == 1){
                    Toast.success('上传成功！');
                    _this.$router.push('/user');
                }else{
                    Toast.fail(data.msg);
                }
            })
        },
        change(state,obj){
            if(obj.type==1){
                state.upload1 = '';
            }else{
                state.upload2 = '';
            }
        },
        closeFun(state){
            state.upload1 = '';
            state.upload2 = '';
        }
    },
    actions: {
        uploadData({commit},parem){
            commit('uploadAxios',parem)
        },
        submitData({commit},_this){
            commit('uploadSubmit',_this)
        }
    },
    getters: {},
}

export default upload;