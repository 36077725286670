import axios from "@/utils/axios";
import { userInfo , company_list , config , user_info, be_doctor } from "@/api";
import { getDomain } from "@/utils";
import domain from "@/utils/config";

let uesr = {
    namespaced: true,
    state: {
        hospital:[],
        info:false,
        switchBool:false,
        addBool:false,
        companyList:[],
        inputBool:false,
        keywords:'',
        keywordsList:[],
        shareBool:false,
        page:0,
        total:1,
        rechargeBool:false,
        money:'',
        playBool:false,
        name: '',
        sex: '1',
        buttonLoading: false,
        error: false,
    },
    mutations: {            //同步方法
        //提交姓名和性别查看是否有资格成为医生以及是否需要上传照片
        setSubmitToBeDoctor(state, _this){
            state.buttonLoading = true;
            const params = {
                name: state.name,
                sex: parseInt(state.sex, 10)
            }
            axios(`/userapi${be_doctor}`, params, "post").then((data) => {
                state.buttonLoading = false;
                if(data.code === 1){
                    sessionStorage.setItem("token", data.data.token);
                    document.cookie = `token=${data.data.token}; expires=Thu, 18 Dec 2043 12:00:00 GMT;domain=${domain};path=/`
                    _this.$router.push('/upload');
                } else {
                    state.error = true;
                    let a = setTimeout(() => {
                        state.error = false;
                        clearTimeout(a)
                    }, 1500)
                }
            })
        },
        //用户信息
        infoAxios(state,_this){
            axios(`/userapi${userInfo}`,{
                event:1
            }).then((data)=>{
                if(data.code==1){
                    this.dispatch('user/accountData',{
                        info:data.data,
                        _this:_this
                    });
                }
            });
        },

        //判断是否是医生
        accountAxios(state,parem){
            axios(`/userapi${user_info}`).then((data)=>{
                if(data.code==1){
                    state.info = {
                        ...parem.info,
                        ...data.data,
                        is_doctor:1
                    };
                    if((data.data.doctor === true || data.data.doctor_material ) && data.data.doctor_material!==2){
                        // 不需要上传资格证书
                        this.dispatch('user/companyData',{main:true});
                    } else {
                        parem._this.$router.push('/upload');
                    }
                } else if (data.code==4003) {
                    // 需要去确认成为医生
                    parem._this.$router.push('/becomeDoctor');
                    state.info = {
                        ...parem.info,
                        is_doctor:0
                    };
                } else {
                    state.info = {
                        ...parem.info,
                        is_doctor:0
                    };
                }
            });
        },

        config(){
            axios(`/authapi${config}`,{
                url:window.location.href,
                appid:getDomain()
            }).then((data)=>{
                let wx  = window.wx;
                if(data.code==1){
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: data.data.app_id, // 必填，公众号的唯一标识
                        timestamp: data.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: data.data.nonce_str, // 必填，生成签名的随机串
                        signature: data.data.signature,// 必填，签名
                        jsApiList: ['updateAppMessageShareData','updateTimelineShareData','chooseWXPay'] // 必填，需要使用的JS接口列表
                    });
                }
            })
        },

        //改变数值
        change(state,data){
            state[data.key] = data.value;
        },

        //分享
        share(state){
            let wx  = window.wx;
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                state.shareBool = true;
                wx.updateAppMessageShareData({
                    title: `${state.info.nikeName?state.info.nikeName:state.info.name}医生请你进行健康评估`, // 分享标题
                    desc: '以便全面深入了解您的身体健康状态', // 分享描述
                    link: `${window.location.protocol}//${window.location.host}/confirmAssess/5?yao_account_id=${state.info.amount_id}&return=2`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                    success: function () {}
                })
                wx.updateTimelineShareData({
                    title: `${state.info.nikeName?state.info.nikeName:state.info.name}医生请你进行健康评估`, // 分享标题
                    link: `${window.location.protocol}//${window.location.host}/confirmAssess/5?yao_account_id=${state.info.amount_id}&return=2`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                    success: function () {}
                })
            });
        },

        //用户列表
        companyList(state,parem={}){
            state.page++;
            console.log(state.companyList.length,state.total,parem.key);
            if((state.companyList.length < state.total)||parem.key){
                axios(`/userapi${company_list}`,{
                    keywords:state.keywords,
                    page:parem.key||parem.main?1:state.page,
                    event:1
                }).then((data)=>{
                    if( data.code == 1 ){
                        if(parem.key ){
                            if(data.data.collect){
                                state.keywordsList = data.data.collect;
                            }
                        }else{
                            if(parem.main){
                                if(data.data.collect){
                                    state.companyList=data.data.collect;
                                }
                                state.total = data.data.page.total;
                            }else{
                                state.companyList = state.companyList.concat(data.data.collect);
                                state.total = data.data.page.total;
                            }
                        }
                    }
                });
            }
        },

        //切换搜索
        inputChange(state){
            state.inputBool = !state.inputBool;
            if(state.inputBool){
                state.keywords = '';
            }
        },

        //添加用户
        addFun(state){
            state.addBool = !state.addBool;
        }
    },
    actions: {              //异步方法
        axiosMain({commit},_this){
            commit('infoAxios',_this)
        },
        switchData({commit},id){
            commit('switchDoctor',id)
        },
        companyData({commit},parem){
            commit('companyList',parem)
        },
        shareData({commit}){
            commit('share')
        },
        stateData({commit},parem){
            commit('stateAxios',parem)
        },
        configData({commit}){
            commit('config')
        },
        accountData({commit},parem){
            commit('accountAxios',parem)
        },
        onSubmitToBeDoctor({commit}, _this){
            commit('setSubmitToBeDoctor', _this)
        }
    },
    getters: {},
}

export default uesr;
