import axios from "../../utils/axios";
let home = {
    namespaced: true,
    state: {
        todos:[],           //初始化
        count:0,
        url:''
    },
    mutations: {            //同步方法
        Add(state){         //加
          state.count++;
        },
        Reduce(state){      //减
          state.count--;
        },
        //发送请求
        Axios(state,params){
            axios('/frpapi/wx/platform/authorize',params)
			.then((data)=>{
                //state.url = makecode(data.data.url);
                window.location.href = data.data.url;
            });
        },
        Code(state,params){
            axios('/frpapi/wx/platform/authorize_callback',params)
			.then((data)=>{
                console.log(data);
            });
        }
    },
    actions: {              //异步方法
        axiosData({commit},params){
            commit('Axios',params)
        },
        axiosCode({commit},params){
            commit('Code',params)
        }
    },
    getters: {        //数据过滤
        
    },
}

export default home;