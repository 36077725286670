import axios from "@/utils/axios";
import { setMac } from "@/api";
import { Notify } from 'vant';
let code = {
  namespaced: true,
  state: {
    data: {},
    code:''
  },
  mutations: {
    //发送请求
    activationCode (state, params) {
      axios (`/api${setMac}`, {
        ...params.data,
        equipment_code:state.code
      },'POST').then ((data) => {
        if(data.code){
          state.data = data.data;
          params._this.$router.push({
            name:'device',
            query: {
              mac:params.data.mac
            }
          });
        }else{
          Notify(data.msg);
        }
      })
    },
    //改变数值
    change(state,data){
        state[data.key] = data.value;
    },
  },
  actions: {
    //异步方法
    axiosData ({ commit }, params) {
      commit ("activationCode", params);
    }
  },
  getters: {},
};

export default code;
