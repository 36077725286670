import axios from "axios";
import { getDomain } from "@/utils";
import domain from "@/utils/config";
import { Toast } from 'vant';

//请求超时时间
axios.defaults.timeout = 10000;

//配置响应拦截器
axios.interceptors.response.use((response)=>{
    //状态过滤
    if(response.status === 200){
        return Promise.resolve(response.data);
    }else{
        return Promise.reject(response.data);
    }
},(error)=>{
    if(error.response && error.response.status == 401){
        let appid = getDomain();
        sessionStorage.setItem("isToken",true);
        window.location.href =  `http://${appid}${domain}?Callback=${encodeURI(window.location.href)}&source=3`;

        //登录
        return Promise.reject(error.response.data);
    }else{
        Toast.fail('请求超时！');
        return Promise.reject({
            code:0,
            msg:'请求超时！'
        });
    }
})

//请求方法
export default function ajax( url = '' , params = {} , type='GET', header , bool ){
    if(!bool){
        let device = sessionStorage.device?sessionStorage.device:'web';
        params = {
            ...params,
            device
        }
    }
    return  new Promise((resolve,reject)=>{
        let promis;
        let headers = {
            'Content-Type':'application/json',
        }

        let token = sessionStorage.token;
        if(token){
            headers['Authorization'] = token;
        }

        if(header){
            headers=header;
        }

        //判断请求类型
        if(type.toUpperCase()==='GET'){           
            promis = axios({
                headers,
                method:'get',
                url,
                params
            })
        }else if(type.toUpperCase()==='POST'){
            promis = axios({
                headers,
                method:'post',
                url,
                data:params,
            })
        }else{
            promis = axios({
                headers,
                method:type.toLowerCase(),
                url,
                data:params,
            })
        }
        //处理结果
        promis.then((response)=>{
            resolve(response);
        }).catch((error)=>{
            reject(error);
        })
    })

}


