//欢迎使用智能中医检测
export const welcome = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/welcome.mp3";
//检测开始，请根据提示完成检测
export const jicebegin = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/jicebegin.mp3";
//请根据页面提示重新扫描
export const yemian = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/yemian.mp3";
//面诊完成，进入舌诊，请将舌苔对准识别区域
export const faceover = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/faceover.mp3";
//进入舌诊，请将舌苔对准识别区域
export const tongue = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/tongue.mp3";
//请将舌头伸出，重新扫瞄
export const tonguefail = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/tonguefail.mp3";
//舌诊完成，开始问诊，请根据题目如实选择答案
export const tongueover = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/tongueover.mp3";
//请选择答案
export const pleasequestion = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/pleasequestion.mp3";
//检测完成，请打开手机微信，扫描屏幕上的二维码
export const jiceover = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/jiceover.mp3";
//正在分析数据，出具检测报告
export const fenxiing = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/fenxiing.mp3";
//请完善个人信息
export const info = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/info.mp3";
//面诊完成，开始问诊
export const facetotongue = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/facetotongue.mp3";
//报告已发送到微信公众号
export const reportover = "https://quanmai-static.oss-cn-hangzhou.aliyuncs.com/mp3/reportover.mp3";
