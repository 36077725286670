import axios from "@/utils/axios";
import { report_info , feedback , userInfo , add_sick , report_node } from "@/api";
import { Toast } from 'vant';
let report = {
    namespaced: true,
    state: {
        data:false,
        content:false,
        contentIndex:0,
        Bool:false,
        feedback:'',
        show: false,
        imagesIndex: 0,
        images: [],
        ask:false,
        user:false
    },
    mutations: {  //同步方法
        userAxios(state,params){
            axios(`/userapi${userInfo}`,{
                ...params
            }).then((data)=>{
                if(data.code==1){
                    state.user = data.data
                }
            })
        },
        //问诊结果
        askAxios(state,params){
            axios(`/api${report_node}`,{
                token:params.token
            })
			.then((data)=>{
                if(data.code==1){
                    state.ask = JSON.parse(data.data.json_string);
                }
            });
        },
        //重置
        clearFun(state){
            state.contentIndex=0;
        },
        customizeAxios(state,params){
            let items = {},bool = true;
            params.data.map((item)=>{
                if(!item.sick_text){
                    Toast.fail('请输入当前证候！');
                    bool = false;
                }
                if(!item.causeDisease){
                    Toast.fail('请输入当前病因！');
                    bool = false;
                }
                if(!item.drug){
                    Toast.fail('请输入推荐药方！');
                    bool = false;
                }
                if(!item.composition){
                    Toast.fail('请输入药方配料！');
                    bool = false;
                }
                if(!item.suitableSick){
                    Toast.fail('请输入适用症状！');
                    bool = false;
                }
                items[item.sick_text] = {
                    'sick_text':item.sick_text,
                    'composition':{
                        'list':[{
                            'name':item.drug,
                            'composition':item.composition,
                            'suitableSick':item.suitableSick,
                            'tips':item.tips
                        }]
                    },
                    'cause_disease':{
                        "list": [{
                            "causeDisease": item.causeDisease
                        }]
                    }
                }
            })
            if(bool){
                axios(`/api${add_sick}`,{
                    token:params.id,
                    sick_text:JSON.stringify(items)
                },'POST')
                .then((data)=>{
                    if(data.code==1){
                        this.dispatch('doctorReport/axiosData',{
                            token:params.id
                        });
                    }
                })
            }
        },
        reportAxios(state,obj){
            axios(`/api${report_info}`,
            {
                token:obj.token
            }).then((data)=>{
                if(data.code==1){
                    state.data = data.data;
                    state.images = [ `${data.data.face_photo_url}`,`${data.data.tongue_photo_url}` ];
                    let contentJSON = JSON.parse(data.data.content);
                    
                    var obj = [];
                    for( let content in contentJSON ){
                        obj.push({
                            key:content,
                            value:contentJSON[content]
                        })
                    }
                    state.content = obj;

                    this.dispatch('doctorReport/userData',{
                        id:data.data.user_id
                    });
                }else{
                    Toast.fail(data.msg);
                }
            });
        },
        feedbackAxios(state,params){
            if(!state.feedback){
                Toast.fail('请输入反馈的建议！');
                return;
            }
            axios(`/api${feedback}`,
            {
                token:params.id,
                content:state.feedback
            },'POST').then((data)=>{
                if(data.code ==1){
                    Toast.success(data.msg);
                    state.Bool=false;
                    state.feedback = '';
                }else{
                    Toast.fail(data.msg);
                }
            });
        },
        change(state,data){
            state[data.key] = data.value;
        },
        addChange(state,data){
            state.customize[data.index][data.key] = data.value;
        }
    },
    actions: {              //异步方法
        axiosData({commit},params){
            commit('reportAxios',params)
        },
        feedbackData({commit},params){
            commit('feedbackAxios',params)
        },
        userData({commit},params){
            commit('userAxios',params)
        },
        customizeData({commit},params){
            commit('customizeAxios',params)
        },
        askData({commit},params){
            commit('askAxios',params)
        }
    },
    getters: {},
}

export default report;