import Vue from 'vue';
import { Swipe, SwipeItem , Checkbox, CheckboxGroup , DatetimePicker , button, Field , NumberKeyboard , Popup , Loading , Icon , Toast , ImagePreview , Uploader,  CellGroup, Cell, Radio, RadioGroup , Cascader } from 'vant';
import App from './App.vue';
import store from './store';
import router from './router';
import '../style.css';

Vue.config.productionTip = false;

Vue.directive("dome",{
    inserted(el,binding){
        const callback = binding.value;
        window.addEventListener("resize",()=>{
            return callback(window.innerWidth);
        });
        el._onResize = callback;
    },
    unbind(el){
        if(el._onResize) return;
        window.removeEventListener("resize",el.removeEventListener);
        delete el._onResize;
    }
});

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(button);
Vue.use(Field);
Vue.use(NumberKeyboard);
Vue.use(ImagePreview);
Vue.use(Popup);
Vue.use(Loading);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(DatetimePicker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Uploader);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Cascader);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
